
import * as Components from "./Sipanglendale"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "sipanglendale"
}
